@font-face {
    font-family: gilroy-regular;
    src: url(../../../public/Gilroy-Regular.ttf);
}

.gilroy-regular{
    font-family: gilroy-regular;
}

@font-face {
    font-family: gilroy-bold;
    src: url(../../../public/Gilroy-Bold.ttf);
  }
  
  .gilroy-bold{
    font-family: gilroy-bold ;
  }

@font-face {
    font-family: gilroy-semibold;
    src: url(../../../public/Gilroy-SemiBold.ttf);
  }
  
  .gilroy-semibold{
    font-family: gilroy-semibold ;
  }

@font-face {
    font-family: gilroy-light;
    src: url(../../../public/Gilroy-Light.ttf);
  }
  
  .gilroy-light{
    font-family: gilroy-light ;
  }

@font-face {
    font-family: gilroy-extrabold;
    src: url(../../../public/Gilroy-ExtraBold.ttf);
  }
  
  .gilroy-extrabold{
    font-family: gilroy-extrabold ;
  }

@font-face {
    font-family: gilroy-medium;
    src: url(../../../public/Gilroy-Medium.ttf);
  }
  
  .gilroy-medium{
    font-family: gilroy-medium ;
  }